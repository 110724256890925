<div class="pt-lg-4 pt-1">
    <h2 class="fs-largest">About</h2>
    <p class="about-text description-text">
        {{description}}.
    </p>
    <div class="row mt-md-4 mt-2">
        <div class="col-md-4 col-4">
            <div class="d-flex">
                <div>
                    <img height="16" width="16" src="assets/icons/clock.svg" alt="clock">
                </div>
                <div class="ms-md-3 ms-2 time-text mt-1 mt-md-0">
                    <p class="mb-0">
                        {{getSpaceWorkingDays(spaceTimings,timingsType.days)}}
                    </p>
                    <p class="m-0">{{getSpaceWorkingDays(spaceTimings,timingsType.time)}}</p>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-8">
            <div class="d-flex me-md-2 me-1">
                <div>
                    <img height="16" width="16" src="assets/icons/location-point.svg" alt="point">
                </div>
                <div class="ms-md-3 ms-2 time-text lh-sm mt-1 mt-md-0">
                    <p class="m-0">{{address}}</p>
                </div>
            </div>
        </div>
    </div>
</div>