import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {
  styleworkStats,
  swapTextContent,
} from 'src/app/Helper/enums';
import { AppRouterService } from 'src/app/shared/services/router/app-router.service';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Cities } from 'src/app/shared/interface/cities';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { WebEngageService } from 'src/app/shared/services/webengage.service';
import { Offer } from 'src/app/shared/interface/banners';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],

})

export class BannerComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox: ElementRef;
  searchCity: string = null;
  imageLoader = true;
  tabActive = true;
  citiesList: Cities[] = [];
  offerBanners:Offer[] = [];
  isBannerLoading = false;
  loading = false;
  companyStats = styleworkStats
  private subs = new SubSink();

  isSearboxTableOpen = false;
  searchSpaceList = [1];
  mform: FormGroup = new FormGroup({
    searchToken: new FormControl()
  });
  div: string;
  textShowOne: boolean;
  textShowTwo: boolean;
  textShowThree: boolean;
  submitted = false;
  closeIntervalHandlerForSwapText: ReturnType<typeof setInterval>;
  closeIntervalHandlerForSwapImg: ReturnType<typeof setInterval>;
  formattedStringOfAddress: string[] = [];
  show = true;
  constructor(
    private appRouterService: AppRouterService,
    private router: Router,
    private restService: RestApiService,
    private renderer: Renderer2,
    private webengageService: WebEngageService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.searchBox.nativeElement) {
        this.isSearboxTableOpen = false;
        this.resetSearchToken();
      } else {
        this.isSearboxTableOpen = true;
      }
    });
  }
  customOptions: OwlOptions = {
    autoplay:true,
    autoplaySpeed:500,
    autoplayHoverPause:true,
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 100,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      500: {
        items: 1
      },
      760: {
        items: 1
      },
      1000: {
        items: 1
      }
    },
    nav: false
  };


  ngOnInit(): void {
    this.closeIntervalHandlerForSwapText = setInterval(this.swapText, 4000);
    setInterval(() => {
      this.show = !this.show;
    }, 2000);

    this.loading = true;
    this.subs.add(
      this.restService.getCities().subscribe(cityData => {
        this.loading = false;
        cityData.forEach((item) => {
          const i = this.citiesList.findIndex(x => x?.id === item?.cityId);
          if (i <= -1) {
            this.citiesList.push(item.city);
          }
        });
      })
    );
    this.getBanners();
  }

  trackByFn(index:number, item:any):number{
    return index
  }
  goto(banner:any, index:number): void {
    window.open(banner.redirectionLink, 'new');
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Banners_Clicked,{bannerName:banner.title, bannerId:index})
  }

  infoText(div: string): void {
    this.div = div;
  }

  webengaegEventExploreSpace(): void{
   this.webengageService.trackEvent(WEBENGAGE_EVENTS.Explore_Space_Clicked,{})
  }

  webengaegEventFlexibleMembership(): void{
   this.webengageService.trackEvent(WEBENGAGE_EVENTS.Flexible_Membership_Clicked,{})
  }

  routeToSpacesExplorationByCityId(city: Cities): void {
    this.appRouterService.routeToSpaceExplorationUsingCitySlug(city);
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Search_By_Location,{
      pageUrl:`/coworking-spaces/${city.slug}`,
      cityName:city.name,
      cityId:city.id,
    })
  }

  swapText(): void {
    const x = document.getElementById('swap-text');
    if (x.innerHTML === swapTextContent.startup) {
      x.innerHTML = swapTextContent.enterPrise;
    } else if (x.innerHTML === swapTextContent.enterPrise) {
      x.innerHTML = swapTextContent.venture;
    } else if (x.innerHTML === swapTextContent.venture) {
      x.innerHTML = swapTextContent.startup;
    } else {
      x.innerHTML = swapTextContent.enterPrise;
    }
  }

  flexibleMembership(): void {

    this.router.navigate(['/membership']);
  }
  getInputValue(): void {
    if (this.mform.value.searchToken.length === 0) {
      this.resetSearchToken();
    }
    // this.spaceFilter.searchToken = this.mform.value.searchToken;
  }
  resetSearchToken(): void {
    this.mform.setValue({
      searchToken: null
    });
  }

  getBanners(): void{
    this.isBannerLoading = true;
    this.subs.add(
      this.restService.getOffers(this.restService.getUserFrontId()).subscribe((res)=>{
        this.isBannerLoading = false;
        this.offerBanners = res;
      },(err)=>{
        this.isBannerLoading = false;
      })
    )
  }

  ngOnDestroy(): void {
    if (this.closeIntervalHandlerForSwapText) {
      clearInterval(this.closeIntervalHandlerForSwapText);
    }
    if (this.closeIntervalHandlerForSwapImg) {
      clearInterval(this.closeIntervalHandlerForSwapImg);
    }
    this.subs.unsubscribe();
  }



}



