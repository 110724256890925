<div class="dialog">
    <div class="left-gradient float-start"></div>
    <button mat-dialog-close  class="btn-close float-end m-3"  aria-label="Close"></button>
    <div class="row px-3">
        <div class="col-lg-5 description-section">
            <div class="pe-4">
                <h1 class=" mt-5 col-lg-9 col-12">
                    Book a Free Tour
                </h1>
                <p class="mt-5 ">
                    SW Golf Course Road, Gurugram 
                </p>
                <span class="content-text">
                    This space provides everything you need for meetings; 
                    web-conferencing capabilities, presentation apps such as PowerPoint, and even a digital whiteboard. 
                    This space provides everything you need for meetings.
                </span>
                <div class="mt-4">
                    <div class="d-flex mb-2">
                        <div>
                            <img height="16" width="16" class="mt-md-2" src="assets/icons/location-point.svg" alt="point">
                        </div>
                        <span class="ms-2 content-text">
                            Vyapar Kendra Road, Sector 40, Gurugram
                        </span>
                    </div>
                    <div class="d-flex mb-2">
                        <div>
                            <img height="16" width="16" src="assets/icons/clock.svg" alt="clock">
                        </div>
                        <span class="ms-2 content-text">
                            Mon - Sat <br>
                            <span class="time-text">08:00 to 20:00</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="right-gradient float-end">
                <img src="assets/icons/Vector 4.png" alt="vector">
            </div>
        </div>
        <div class="col-lg-7">
          <div class="py-5 px-4">
            <p class="form-heading">Schedule your Free Tour by filling the form below</p>
            <div class="mt-4 col-lg-8">
                <label class="form-label input-label">Your Full Name</label>
                <div class="input-container">
                    <input class="input" type="text"  placeholder="John Doe">
               </div>
            </div>
            <div class="mt-3 col-lg-8">
                <label class="form-label input-label">Your Email<span class="ms-1 select-text">(optional)</span></label>
                <div class="input-container">
                    <input class="input" type="text"  placeholder="John.doe@gmail.com">
               </div>
            </div>
            <div class="mt-3 col-lg-8">
                <label class="form-label input-label">Your Contact Number</label>
                <div class="d-flex">
                    <div class="select-container">
                        <select class="input select-text">
                            <option selected>+91</option>
                            <option value="1">+11</option>
                            <option value="2">+12</option>
                            <option value="3">11</option>
                          </select>
                    </div>
                    <div class="input-container ms-2">
                     <div class="d-flex">
                        <input class="input w-75" type="text"  placeholder="9354876521">
                        <a class="link-text" (click)="verifyYourNumberDialog()">VERIFY</a>
                     </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 col-lg-10">
                <label class="form-label input-label">Select Date & Time</label>
                <div class="d-flex">
                    <div class="select-container d-flex">
                       <div>
                        <img src="assets/icons/Calendar-Icon.png" alt="calender">
                       </div>
                        <input class="input w-75 ms-2" type="text" onfocus="(this.type='date')" onblur="(this.type='text')" placeholder="Select Date">
                    </div>
                    <div>
                    <div class="select-container ms-4 d-flex ">
                        <div>
                            <img height="16" width="16" src="assets/icons/clock.svg" alt="clock">
                        </div>
                    
                      <input class="input w-75 ms-2" type="text" onfocus="(this.type='time')" onblur="(this.type='text')"  placeholder="Select Time">
                    </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 pt-2 col-lg-8">
                <button 
                [disabled]="true"
                [ngClass]="false? 'btn btn-primary-base btn-text text-center w-100':'btn disabled-btn btn-text text-center w-100'">
                   Book your Free Tour
                </button>                                    
            </div>
          </div>
        </div>
    </div>
</div>
