import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WEBENGAGE_EVENTS } from 'src/app/Helper/constant';
import { BASIC_INFO } from 'src/app/Helper/image-path';
import { RestApiService } from 'src/app/shared/services/common-services/rest-api.service';
import { SweetAlertMessageService } from 'src/app/shared/services/sweet-alerts-message/sweet-alert-message.service';
import { WebEngageService } from 'src/app/shared/services/webengage.service';
import { SubSink } from 'subsink';

 export interface NewsletterSubscription {
  emailId: string; 
  subscribeToAll: boolean;
  subscriptions: string[]; 
}

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit, OnDestroy {
  footerNavFirst = [
    { id: 1, title: 'Career', link: 'career' },
    { id: 2, title: 'Blogs and Resources', link: 'https://blogs.stylework.city/' },
    { id: 3, title: 'Managed Space', link: 'managed-space'},
    { id: 4, title: 'About Us', link: 'about-us' },
    { id: 5, title: 'Contact Us', link: 'contact-us' },
    { id: 6, title: 'Channel Partner',link:'https://forms.gle/6KVgHsWqZSwQ8FS46'},
  ];

  footerNavSecond = [
    { id: 2, title: 'List your space', link: 'list-your-space' },
    { id: 3, title: 'Membership Plans', link: 'membership'},
    { id: 4, title: 'Enterprise ', link: 'enterprise'},
    { id: 5, title: 'Our Partners', link: 'our-partners'},
  ];

  socialIcon = [
    { id: 1, icon: 'assets/icons/Twitter.png', link: 'https://twitter.com/styleworkcity', alt: 'twitter'},
    { id: 2, icon: 'assets/icons/facebook.svg', link: 'https://www.facebook.com/styleworkCity/', alt: 'facebook'},
    { id: 3, icon: 'assets/icons/instagram.svg', link: 'https://www.instagram.com/stylework.city/', alt: 'instagram'},
    { id: 4, icon: 'assets/icons/linkedin.svg', link: 'https://www.linkedin.com/company/style-work/', alt: 'linkedin'},
  ];

  basicInfo = BASIC_INFO;
  isLoading = false;
  message = new FormControl('', [Validators.required, Validators.email]);
  mode = 'indeterminate';
  value = 30;
  diameter = 30;
  year = new Date().getFullYear()
  private sub = new SubSink();

  constructor(
    private restService: RestApiService,
    private sweetAlertService: SweetAlertMessageService,
    private router: Router,
    private webengageService: WebEngageService
  ) {}

  ngOnInit(): void {}

  postMessage(): void {
    if(this.message.invalid) {
      return;
    }
    this.isLoading = true;
    const subscriptionBody: NewsletterSubscription = {
      emailId: this.message.value,
      subscribeToAll: true,
      subscriptions: []
    }
    this.sub.add(
      this.restService.postMessage(subscriptionBody)
        .subscribe(
          res => {
            this.isLoading = false;
            this.sweetAlertService.successAlertDialog('For subscribing to our newsletter!');
            this.message.reset();
          }, err => {
            this.isLoading = false;
            this.sweetAlertService.errorAlertDialog();
          }
        )
    );
  }
  refreshRoute() {
    this.webEngageFooterEvent('FAQs')
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/faq']);
    });
  }

  webEngageFooterEvent(eventData:string):void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Footer_Clicked,{name:eventData})
  }

  webEngageSocialMediaEvent(eventData:string):void {
    this.webengageService.trackEvent(WEBENGAGE_EVENTS.Social_Media_Clicked,{name:eventData})
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
